<template>
    <el-container>
      <el-main>
        <head-layout
          head-title="我的考试"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page.sync="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              size="small"
              @click="viewQuestions(row)">
              查看试题
            </el-button>
            <el-button
              type="text"
              size="small"
              v-if="row.examStatus == 2||row.whetherBeQualifiedOrNot == 0"
              @click="toExam(row)">
              考试
            </el-button>
            <el-button
              type="text"
              size="small"
              v-if="row.examStatus == 1&&row.storageMode!='additionalRecording'"
              @click="viewPaper(row)">
              考试结果
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="examinationRecord(row)">
              考试记录
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {verifyAnswer} from "@/api/training/trainingEducation";
import {mapGetters} from "vuex";
import {getEduTree} from "@/api/training/examinationPaper";
import {getList, getDetailById, getDetail, add, update, remove, trainingInquiry} from "@/api/training/eduuserexam";


export default {
  name: "eduuserexam",
  components: {
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      taskCode: '',
      query: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      tableLoading: true,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.edupaper_add, true),
        viewBtn: this.vaildData(this.permission.edupaper_view, true),
        delBtn: this.vaildData(this.permission.edupaper_delete, true),
        editBtn: this.vaildData(this.permission.edupaper_edit, true)
      };
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试状态",
          prop: "examStatus",
          type: "select",
          span: 4,
          dicData: [],
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_status',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "专业分类",
          prop: "subjectType",
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "id",
          },
          span: 4,
          dataType: 'number',
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "是否合格",
          prop: "whetherBeQualifiedOrNot",
          type: "select",
          dataType: "string",
          span: 4,
          dicData: [],
          dicUrl: '/api/sinoma-system/dict/dictionary?code=yes_no',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ]
    },
    tableOptions() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: '培训任务名称',
            prop: "trainTheme",
            // slot:true,
            align: "left",
            cell: true,
            overHidden: true,
            width: 240,
          },
          {
            label: '试卷名称',
            prop: "paperName",
            // slot:true,
            align: "left",
            cell: true,
            overHidden: true,
            width: 240,
          },
          {
            label: "考试状态",
            prop: "examStatus",
            type: "select",
            width: 140,
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_status',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: 'center',
            overHidden: true,
          },
          /*注意请不要删除*/
          {
            label: '是否合格',
            prop: 'whetherBeQualifiedOrNot',
            type: 'switch',
            props: {
              label: "label",
              value: "value"
            },
            dataType: 'string',
            align: "center",
            span: 4,
            overHidden: true,
            dicData: [
              {
                label: '否',
                value: "0"
              },
              {
                label: '是',
                value: "1",
              },
            ],
          },

          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            align: 'center',
            overHidden: true,
          },
          {
            label: "答题时长(分钟)",
            prop: "answerTime",
            width: 140,
            align: 'right',
            overHidden: true,
          },
          {
            label: "开始时间",
            prop: "startTime",
            type: "datetime",
            width: 200,
            align: 'center',
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          {
            label: "结束时间",
            prop: "endTime",
            type: "datetime",
            width: 200,
            align: 'center',
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          // {
          //   label: "作业类型",
          //   prop: "examType",
          //   type: "select",
          //   align: 'center',
          //   width: 130,
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_TYPE',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   overHidden: true,
          // },
          {
            label: "得分",
            prop: "totalScore",
            width: 80,
            align: 'right',
            overHidden: true,
          },
        ]
      }
    },
  },
  methods: {
    initData() {
      getEduTree(this.userInfo.tenantId).then(res => {
        const column = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column.dicData = res.data.data
        this.findObject(this.$refs.gridHeadLayout.searchColumns, 'subjectType').dicData = res.data.data
      })
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      if (this.taskCode === undefined) {
        getList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total
          this.tableData =  data.records;
          this.tableLoading = false;
          this.$refs.gridLayOut.selectionClear();
        });
      } else {
        getDetailById(this.taskCode).then(res => {
          this.$refs.gridLayOut.page.total = 1;
          this.tableData.push(res.data.data);
          this.tableLoading = false;
          this.taskCode = undefined;
          this.$refs.gridLayOut.selectionClear();
        })
      }
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    // 查看试题
    viewQuestions(row) {
      let e = {id: row.paperId}
      this.$router.push({
        path: '/training/paper/edupaperView',
        query: {
          row: JSON.stringify(e),
          type: 'view',
        }
      });
    },
    // 跳转到考试界面
    toExam(row) {
      //如果需要增加线下练习可在这里进行增加逻辑放开注释的代码就是线下练习
      // 满足条件即可答题
      verifyAnswer(row).then((res) => {
        if (res.data.data) {
          console.log(row, "row")
          this.$router.push({
            path: '/business/safetyenment/train/trainingEducation/eduexampaper',
            query: {
              row: JSON.stringify({
                id: row.id,
                paperId: row.paperId.toString(),
                examId: row.trainPlanId,
                type: 'edit'
              }),
            }
          });
        } else {
          this.$message.warning("目标课时未达标或培训已过期！");
        }
      })
      //此注释代码有用请勿删除！！！！！
      //此注释代码有用请勿删除！！！！！
      //此注释代码有用请勿删除！！！！！
      // this.$router.push({
      //     path: '/safetyenment/train/myExam/eduexampaper',
      //     query: {
      //       row: JSON.stringify({
      //         id: row.id,
      //         paperId: row.paperId,
      //         examId: row.id,
      //         type: 'edit'
      //       }),
    },
    viewPaper(row) {
      this.$router.push({
        path: '/safetyenment/train/myExam/examResults',
        query: {
          row: JSON.stringify({
            id: row.oldId,
            paperId: row.paperId,
            examId: row.id,
            type: 'view'
          }),
        }
      });
    },
    examinationRecord(row){
      this.$router.push({
        path: '/safetyenment/train/myExam/eduuserexamParticulars',
        query: {
            row: JSON.stringify(row),
            type: 'records'
        }
      });
    }
  },
  mounted() {
    const taskId = this.$route.query.taskId;
    const id = this.$route.query.id;
    this.taskCode = taskId;
    if (id != undefined) {
      this.query.id = id
    }
    this.onLoad(this.page, this.query)
    this.initData()
  },
  created() {

  }
}
</script>

<style scoped>

</style>
